import React from 'react';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import Seo from './seo';
import Layout from './layout';

function IndexPage({ data, location }) {
  const { title, date, author_name, author_image } = data.mdx.frontmatter;

  const description = data.mdx.frontmatter.description || data.mdx.excerpt;
  const authorImageFluid = author_image.childImageSharp.fluid;

  return (
    <Layout>
      <Seo title={title} description={description} path={location.pathname} />

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="my-4 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                {title}
              </span>
            </h1>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <Img
                  fluid={authorImageFluid}
                  className="h-10 w-10 rounded-full"
                  alt={`${author_name} image`}
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {author_name}
                </p>
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={date}>{date}</time>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <MDXProvider>
              <MDXRenderer frontmatter={data.mdx.frontmatter}>
                {data.mdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    mdx(id: { eq: $id }) {
      body
      excerpt(pruneLength: 150)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        description
        author_name
        author_image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
